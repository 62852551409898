import React, { useState } from "react"
import card1 from '../img/card-ironhouse.png'
import card2 from '../img/card-ironstreet.png'
import card3 from '../img/card-screen.png'
import card4 from '../img/card-digital.png'
import card5 from '../img/card-flyer.png'

const CarosselCards = () => {
  const images = [
    { src: card1 },
    { src: card2 },
    { src: card3 },
    { src: card4 },
    { src: card5 },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [startX, setStartX] = useState(0);
  const [isSwiping, setIsSwiping] = useState(false);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const getPreviousIndex = (index) => (index - 1 + images.length) % images.length;
  const getNextIndex = (index) => (index + 1) % images.length;
  const setSlide = (index) => {
    setCurrentIndex(index);
  };

 
   const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
    setIsSwiping(true);
  };

  const handleTouchMove = (e) => {
    if (!isSwiping) return;

    const touchEndX = e.touches[0].clientX;
    const distance = touchEndX - startX;

    if (distance > 50) {
      prevSlide();
      setIsSwiping(false);
    } else if (distance < -50) {
      nextSlide();
      setIsSwiping(false);
    }
  };
  
  return (
    <section className="CarosselCards">
      <h1 className="carosselTitle">Descubra o modelo perfeito para o seu sucesso:</h1>
      <div 
      className="carosselContainer"
      onTouchStart={handleTouchStart}  
      onTouchMove={handleTouchMove}
      >

        <div className="carosselSlides">
          <div className="carosselImageWrapper prevSlide">
            <img src={images[getPreviousIndex(currentIndex)].src} alt="Previous Slide" />
          </div>
          <div className="carosselImageWrapper mainSlide">
            <img src={images[currentIndex].src} alt="Current Slide" />
          </div>
          <div className="carosselImageWrapper nextSlide">
            <img src={images[getNextIndex(currentIndex)].src} alt="Next Slide" />
          </div>
        </div>
      </div>
      <div className="carosselDots">
          {images.map((_, index) => (
            <span
              key={index}
              className={`dot ${currentIndex === index ? 'active' : ''}`}
              onClick={() => setSlide(index)}
            ></span>
          ))}
        </div>
    </section>
  );
};

export default CarosselCards;