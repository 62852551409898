import React from 'react'
import { Icon } from '@iconify/react'

const Benefits = () => (
  <section className="benefits">
    <h2 className="benefitsTitle">Benefícios principais de todas as modalidades:</h2>

    <div className="circlesContainer">
      <div className="circleItem">
        <div className="circle">
          <Icon icon="lucide:smile-plus" />
        </div>
        <h3 className="circleTitle">
          <span>TRABALHO</span>
          <span>DINÂMICO</span>
        </h3>
      </div>

      <div className="circleItem">
        <div className="circle">
          <Icon icon="cil:dollar" />
        </div>
        <h3 className="circleTitle">
          <span>COMISSÕES</span>
          <span>ATRATIVAS</span>
        </h3>
      </div>

      <div className="circleItem">
        <div className="circle">
          <Icon icon="ic:baseline-model-training" />
        </div>
        <h3 className="circleTitle">
          <span>TREINAMENTO</span>
          <span>ESPECIALIZADO</span>
        </h3>
      </div>

      <div className="circleItem">
        <div className="circle">
          <Icon icon="material-symbols:support-agent" />
        </div>
        <h3 className="circleTitle">
          <span>SUPORTE</span>
          <span>COMPLETO</span>
        </h3>
      </div>
    </div>

    <h2 className="intermedTitle">Além da intermediação de veículos, você também pode faturar com:</h2>

    <div className="circlesContainer">
      <div className="intermedItem">
        <div className="intermed">
          <Icon icon="lets-icons:check-fill" />
        </div>
        <div className='intermedBox'>
          <h3 className="intermedSubtitle">
            <span>Garantias</span>
            <span>Estendidas</span>
          </h3>
        </div>
      </div>

      <div className="intermedItem">
        <div className="intermed">
          <Icon icon="fa6-solid:car-on" />
        </div>
        <div className='intermedBox'>
          <h3 className="intermedSubtitle">
            <span>Financiamentos</span>
            <span>Personalizados</span>
          </h3>
        </div>
      </div>

      <div className="intermedItem">
        <div className="intermed">
          <Icon icon="clarity:car-solid" />
        </div>
        <div className='intermedBox'>
          <h3 className="intermedSubtitle">
            <span>Consórcios</span>
          </h3>
        </div>
      </div>

      <div className="intermedItem">
        <div className="intermed">
          <Icon icon="ant-design:product-filled" />
        </div>
        <div className='intermedBox'>
          <h3 className="intermedSubtitle">
            <span>Diversos Produtos</span>
            <span>do Grupo M1 </span>
          </h3>
        </div>
      </div>
    </div>

    <div className='lucreContainer'>
      <h1 className='lucreTitle'>
        Aumente sua renda agora! Lucre a partir de <span className='spanLucre'>R$5.000,00*</span>
        <p className='lucreSubtitle'>*Valores aproximados de acordo com o desempenho e modalidade.</p>
      </h1>
    </div>

  </section>
);

export default Benefits;