import React from 'react'
const Video = () => (
  <>
    <section className="video">
      <div className='borderVideo'>
        <iframe width="100%" height="100%"
          src="https://www.youtube.com/embed/jZAJY0YU0Xg?autoplay=1&rel=0"
          frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen>
        </iframe>
      </div>

    </section>
  </>
);

export default Video;