import React, { useEffect, useState } from 'react'
import logo from '../img/logom1motors.png'
import Video from './Video';
import { Icon } from '@iconify/react'

const Header = () => {
  const [currentWord, setCurrentWord] = useState('INDEPENDÊNCIA FINANCEIRA');
  const words = [
    'FLEXIBILIDADE',
    'ESTABILIDADE<br />E SEGURANÇA',
    'LIBERDADE',
    'INDEPENDÊNCIA<br />FINANCEIRA'
  ];

  useEffect(() => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      currentIndex = (currentIndex + 1) % words.length;
      setCurrentWord(words[currentIndex]);
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <header className="header">
        <div className="logoContainer">
          <img src={logo} alt="Logo" className="logo" />
        </div>
      </header>

      <section className="banner">
        <div className="bannerImageContainer">
          <div className="bannerImage" />
        </div>
        <div className='bannerText'>
          <h1 className='IronTitle'>Nunca Foi tão Fácil Mudar de Vida!</h1>
        </div>
        <Video />

        <div className="independenceText">
          <h1 className='independenceTitle'>
            Conquiste a sua<br />
            <span className="independenceSubtitle" dangerouslySetInnerHTML={{ __html: currentWord }}></span>
          </h1>

          <div className="iconsContainer">
            <Icon icon="gravity-ui:play-fill" className="independenceIcon" />
            <Icon icon="gravity-ui:play-fill" className="independenceIcon" />
            <Icon icon="gravity-ui:play-fill" className="independenceIcon" />
            <Icon icon="gravity-ui:play-fill" className="independenceIcon" />
          </div>

          <p className='independenceMindText'>
            Transforme sua paixão por carros em um negócio de sucesso. Seja um franqueado M1 Motors.
          </p>
        </div>
      </section>
    </>
  )
};

export default Header;