import React, { useState, useEffect } from 'react'
import kit1 from '../img/homework.png'
import kit2 from '../img/kit.png'
import kit3 from '../img/kit.png'
import kit4 from '../img/kit.png'
import kit5 from '../img/kit.png'

const images = [kit1, kit2, kit3, kit4, kit5];

const KitModalidades = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  const visibleImages = [
    images[currentIndex],
    images[(currentIndex + 1) % images.length],
    images[(currentIndex + 2) % images.length],
  ];

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <section className="benefits">
      <h2 className="benefitsKitTitle">Kit M1 para o Franqueado</h2>
      <div className="carouselContainer">
        {visibleImages.map((image, index) => (
          <div key={index} className="carouselItem">
            <img src={image} alt={`Kit Franqueado ${index}`} className="carouselImage" />
          </div>
        ))}
      </div>

       {/* Dots para navegação apenas no mobile */}
       <div className="carouselDots">
        {images.map((_, index) => (
          <div
            key={index}
            className={`dot ${currentIndex === index ? 'active' : ''}`}
            onClick={() => goToSlide(index)}
          ></div>
        ))}
      </div>

    </section>
  );
};

export default KitModalidades;